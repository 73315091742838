import React from 'react';
import styled from "styled-components";
import { Link } from 'react-router-dom';

import PageContainer from '../PageContainer';
import Label from '../Label';
import View from '../View';


import { headColor, tabBarActiveColor, tabBarInactiveColor } from "../../theme";

const Icon = styled.img`
    height: 40px;

    :first-child {
        fill: ${props => props.color};
    }
`;

const Bar = styled.div`
    width: 100vw;
    height: 80px;
    background: ${props => props.theme.theme.footColor};
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 20px;
    position: fixed;
    bottom: 0px;
`;

const BottomTabBar = ({children, location, ...rest}) => {
    const routes = Array.isArray(children) ? children : [children]
    const routeName = location.pathname;
    const route = routes.find(route => route.props.path === routeName);

    const getHeader = () => {
        if (!route) {
            return null;
        }

        const {component} = route.props;

        if (!component || !component.Header) {
            return null;
        }

        return <component.Header location={location} />;
    }

    return <PageContainer style={{flexDirection: "column", padding: 0, height: '100vh'}}>
        {
            getHeader()
        }
        <div style={{ paddingBottom: 80 }}>
            {children}
        </div>
        <Bar>
            {
                routes.map(route => (
                    <Link to={route.props.mainPath}>
                        <View style={{alignItems: 'center'}}>
                            {
                                route.props.icon 
                                ? <Icon alt="" src={route.props.mainPath === routeName ? route.props.iconSelected : route.props.icon} />
                                : <div style={{width: 40, height: 40, border: "1px solid #FFF"}} />
                            }
                            <Label color={route.props.mainPath === routeName ? tabBarActiveColor : tabBarInactiveColor} fontSize={12}>{route.props.name}</Label>
                        </View>
                    </Link>
                ))
            }
        </Bar>
    </PageContainer>
};

export default BottomTabBar;

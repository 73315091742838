import React from 'react';

import View from '../../View';
import Label from '../../Label';
import { whitePurble, green, inputContainerColor, red, yellow } from '../../../theme';
import { format } from 'currency-formatter';
import styled from 'styled-components';

const BetRowContainer = styled.div`
    display: flex;
    align-self: stretch;
    flex-direction: row;
    justify-content: space-between; 
    // background: ${props => props.theme.theme.inputColor};
    margin-bottom: 3; 
    padding: 5px 0;
`;

const BetRow = ({name, status, isWinner, ...bets}) => {
    let betList = [];
    
    for (const betKey in bets) {
        betList = [...betList, (
            <BetRowContainer>
                <View style={{flexDirection: 'row'}}>
                    <Label fontUnit={'vw'} fontSize={3} color={green}>APOSTADO: </Label>
                    <Label fontUnit={'vw'} fontSize={3}>{format(bets[betKey].value / 100, { code: "BRL" })}</Label>
                </View>
                <View style={{flexDirection: 'row'}}>
                    <Label fontUnit={'vw'} fontSize={3} color={green}>RATEIO: </Label>
                    <Label fontUnit={'vw'} fontSize={3}>{bets[betKey].ratio.toFixed(1).replace('.', ',')}</Label>
                </View>
                <View style={{flexDirection: 'row'}}>
                    {
                        bets[betKey].deleted ? (
                            <Label fontUnit={'vw'} fontSize={3} color={yellow}></Label>
                        ) : (
                            status ? (
                                <>
                                    <Label fontUnit={'vw'} fontSize={3} color={isWinner ? green : red}>RESULTADO: </Label>
                                    <Label fontUnit={'vw'} fontSize={3}>
                                        {
                                            isWinner ? format(((bets[betKey].value)/100) * bets[betKey].ratio, { code: "BRL" }) : format(((bets[betKey].value)/100) * (-1), { code: "BRL" })
                                        }
                                    </Label>
                                </>
                            )
                            : 
                            (
                                <>
                                    <Label fontUnit={'vw'} fontSize={3} color={green}>EM ANDAMENTO</Label>
                                </>
                            )
                        )
                    }
                </View>
            </BetRowContainer>
        )]
    }

    return betList;
}

const HorseRow = ({horses, finish, status}) => {
    let horseList = [];

    for (const horseKey in horses) {
        horseList = [...horseList, (
            <View>
                <Label fontSize={14}>{horses[horseKey].name}</Label>
                <View>
                    <BetRow {...horses[horseKey]} status={status} isWinner={finish ? finish.win === horseKey : false} />
                </View>
            </View>
        )];
    }

    return horseList;
}

const Row = ({name, status, finish, ...horses}) => {
    console.log('finish =>', finish)
    return (
        <View style={{borderBottom: `1px solid ${whitePurble}`, paddingBottom: 5}}>
            <Label fontSize={20}>{name}</Label>
            <HorseRow horses={horses} status={status} finish={finish} />
        </View>
    )
}

const MyBets = ({data, status}) => {
    return <View style={{padding: "10px 20px", overflow: "auto", flexShrink: 0}}>
        {
            data.map(bet => 
                <Row {...bet} status={status} />
            )
        }
    </View>
};

export default MyBets;

import { LOAD_USER, LOGOFF_USER, ADD_MONEY, RESCUE_MONEY, UPDATE_MONEY, UPDATE_PAYMENTS, CHANGE_THEME } from "../actions/types";

const UserReducer = (state = [], action) => {
    switch(action.type) {
        case LOAD_USER:
            return action.payload;
        case LOGOFF_USER:
            return {};
        case ADD_MONEY:
            return {...state, money: action.newAmount, creditCard: action.creditCard, payments: action.payments}
        case RESCUE_MONEY:
            return {...state, money: action.newAmount, payments: action.paymentsList}
        case UPDATE_MONEY:
            return {...state, money: action.newAmount}
        case UPDATE_PAYMENTS:
            return {...state, payments: action.payments}
        case CHANGE_THEME:
            console.log(CHANGE_THEME, {...state, theme: action.theme})
            return {...state, theme: action.theme}
        default:
            return state;
    }
};

export default UserReducer;
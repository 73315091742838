import styled from "styled-components";

export const baseStyle = ({width, theme}) => `
    width: ${width || 'calc(100% - 40px)'};
    height: 40px;
    border-radius: 5px;
    border: 1px solid #93a3d5;
    padding: 10px;
    font-size: 16px;
    background: ${theme.theme.inputColor};
    color: ${theme.theme.color};

    ::placeholder {
        color: ${theme.theme.color};
    }

    :focus {
        outline: #93a3d5;
    }
`;

const Input = styled.input`${props => baseStyle(props)}`;

export default Input;

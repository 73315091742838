import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useParams, useHistory } from 'react-router-dom';
import firebase from 'firebase';
import ReactLoading from "react-loading";

import PageContainer from '../../components/PageContainer';
import View from '../../components/View';
import Label from '../../components/Label';
import Header from '../../components/Header/Home';
import { formatDate } from '../../utils';
import BetGrid from '../../components/grid/bet';
import { whiteBlue, headColor, grey, green, whitePurble, inputContainerColor, yellow } from '../../theme';
import Input from '../../components/Input/Currency';
import Button from '../../components/Button';
import { format } from 'currency-formatter';

import { CurrencyContainer } from './style';

const BetData = ({user, theme}) => {
    const {betKey} = useParams();
    const history = useHistory();
    const [data, setData] = useState({});
    const [horses, setHorses] = useState([]);
    const [selected, setSelected] = useState("");
    const [value, setValue] = useState("");
    const [maskedValue, setMaskedValue] = useState("");
    const [hasMoney, setHasMoney] = useState(true);

    const getHorse = ({name, update}) => ({
        name,
        ...update[update.length - 1],
        index: update.length - 1
    })

    const getStatus = ({betAt, betEnd, raceDate}) => {
        const now = new Date().getTime();
      
        if (now < betAt) {
          // Se hoje for antes da data de inicio de apostas
          return 0;
        } else if (now < betEnd && now > betAt) {
          // Se hoje for durante o período de apostas
          return 1;
        } else if (raceDate < now) {
          return 2;
        }
    }; 

    useEffect(() => {
        const loadBet = async () => {
            try {
                const obj = (await firebase.database().ref(`bets/${betKey}`).once("value")).val();
                let horses = [];
    
                for (const horseKey in obj.horses) {
                    horses = [...horses, {...getHorse(obj.horses[horseKey]), key: horseKey}]
                }
    
                obj.status = getStatus(obj);

                setData(obj);
                setHorses(horses);
            } catch(e) {
                console.log(e);
            }
        }

        loadBet();
    }, [betKey]);

    const verifyValue = () => {
        const horse = horses.find(horse => horse.key === selected);

        if (!horse) {
            alert("Selecione um cavalo!");
        } else if (value < 2) {
            alert("A aposta mínima é de R$2,00");
        } else if ((horse.limit - horse.amount) < parseInt(value * 100, 10)) {
            if ((horse.limit - horse.amount) < 2) {
                alert(`O cavalo ${horse.name} não esta disponível para apostas`);
            } else {
                alert(`A aposta máxima é de ${format((horse.limit - horse.amount)/100, { code: "BRL" })}`);
            }
        } else if (!user.money || value > (user.money/100)) {
            setHasMoney(false);
        } else {
            history.push({
                pathname: "confirm",
                state: {
                    bet: data,
                    betKey,
                    horse,
                    value
                }
            })
        }
    }

    const getExpectedValue = () => {
        const horse = horses.find(horse => horse.key === selected);

        console.log(horse)

        if (horse) {
            return format(value * horse.ratio, { code: "BRL" });
        }

        return format(value, { code: "BRL" });
    }

    if (!hasMoney) {
        return <PageContainer style={{justifyContent: "flex-start", alignItems: 'center', height: 'inherit'}}>
            <Header user={user} />
            <View style={{alignItems: 'center', padding: 20}}>
                <img 
                    alt=""
                    src={require("../../images/img_not_found.png")}
                    style={{
                        width: '75vw',
                        height: '75vw'
                    }}
                    />
                <View style={{marginBottom: 20}}>
                    <Label color={yellow} style={{textAlign: 'center'}}>Você não tem créditos na sua conta!</Label>
                    <Label style={{textAlign: 'center'}}>Mas não fique triste, adicione créditos de uma forma rápida e segura</Label>
                </View>
                <View>
                    {/* <Button
                        style={{width: '100%', background: 'transparent', border: `1px solid ${green}`}}
                        onClick={() => history.push("/addmoney/")}
                    >
                        <Label color={green}>ADICIONAR DINHEIRO</Label>
                    </Button> */}
                </View>
            </View>
        </PageContainer>
    }

    if (data.status === null) {
        return <PageContainer style={{justifyContent: "space-between", height: 'inherit'}}>
            <ReactLoading type="spin" />
        </PageContainer>
    }

    return <PageContainer style={{justifyContent: "space-between", height: 'inherit'}}>
        <Header user={user} />
        <View style={{flex: 1}}>
            <View style={{padding: "20px 20px 10px", backgroundColor: theme.homeTitle}}>
                <View>
                    <Label style={{fontSize: 16}}>
                        {data.name}
                    </Label>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Label style={{fontSize: 14, color: grey}}>
                            {formatDate(data.raceDate)}
                        </Label>


                        <Label style={{fontSize: 14, color: grey}}>
                            Aposta mínima R$ 2,00
                        </Label>
                    </View>
                </View>
            </View>
            <BetGrid {...data} horses={horses} selected={selected} onSelect={setSelected}/>
        </View>
        {
            (data.status === 1 && !!user.money) && (
                <CurrencyContainer>
                    <View style={{padding: "20px 0 20px 20px"}}>
                        <Label>Valor da aposta</Label>
                        <Input 
                            style={{border: "none"}}
                            color={green}
                            onChangeEvent={(event, maskedvalue, floatvalue) => {
                                setMaskedValue(maskedvalue);
                                setValue(floatvalue);
                            }}
                            value={maskedValue}
                        />
                    </View>
                    <View style={{padding: "0 20px 20px"}}>
                        <Label>Ganho possível</Label>
                        <Label>{getExpectedValue()}</Label>
                    </View>
                    <Button onClick={verifyValue} color="green" style={{width: "100%", padding: "20px 0 30px 0px" , height: 60, borderRadius: 0}}>
                        <Label color={theme.bodyColor}>APOSTAR</Label>
                    </Button>
                </CurrencyContainer>
            )
        }
        
    </PageContainer>
};

const mapStateToProps = ({user}) => ({user, theme: user.theme});

export default connect(mapStateToProps)(BetData);

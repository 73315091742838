import React from 'react';
import { connect } from "react-redux";

import PageContainer from "../../components/PageContainer";
import Label from '../../components/Label';
import View from '../../components/View';
import Button from '../../components/Button';
import { whiteBlue } from '../../theme';

import { resetForm } from "../../actions";

const RescueDone = ({ history, resetForm }) => {
    return <PageContainer style={{height: '100%', justifyContent: 'space-evenly'}}>
        <View></View>
        <View style={{alignItems: "center"}}>
            <img 
                src={require('../../images/img_confirmation.png')}
                alt=""
                style={{
                    width: '60vw',
                    height: '60vw'
                }}
            />
            <Label style={{marginBottom: 20}}>Requisição enviada com sucesso</Label>
            <Button 
                onClick={() => {
                    resetForm();

                    history.push("/wallet");
                }}
                style={{
                    background: 'transparent',
                    borderColor: whiteBlue
                }}
            >
                <Label color={whiteBlue}>FECHAR</Label>
            </Button>
        </View>
        <View />
    </PageContainer>
};

export default connect(null, { resetForm })(RescueDone);

import React from 'react';
import Loading from "react-loading";

import View from '../../components/View';

export default () => {

    return (
        <View style={{height: "100vh", justifyContent: 'center', alignItems: 'center'}}>
            <Loading type="spin" width="30px" height="30px" />
        </View>
    )
};


export const purple = '#292a48';
export const whitePurble = '#FFF';
export const whiteBlue = '#6acce9';
export const white = '#FFF';
export const green = '#509C2C';
export const red = '#ef5350';
export const yellow = '#ffca28';
export const grey = '#AEAEAE';

export const success = green;
export const error = red;
export const warning = yellow;

export const headColor = "#121212";
export const bodyColor = "#000";
export const inputContainerColor = "#262626";

export const background1 = success;
export const background2 = error;

export const tabBarActiveColor = "#509C2C";
export const tabBarInactiveColor = "#858585";
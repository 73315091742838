import { EDIT_FORM, RESET_FORM } from "../actions/types";

export default (state = {}, {type, payload}) => {
    console.log(type)
    switch(type) {
        case EDIT_FORM:
            return {
                ...state, ...payload
            }
        case RESET_FORM:
            return {};
        default:
            return state;
    }
}
import React, { useEffect, useState } from 'react';
import Loading from "react-loading";
import { connect } from "react-redux";
import { format } from 'currency-formatter';
import { Button } from 'react-bootstrap';
import firebase from 'firebase';

import PageContainer from "../../components/PageContainer";
import Label from '../../components/Label';
import LinearGradient from '../../components/LinearGradient';
import View from '../../components/View';
import Grid from '../../components/grid/wallet';

import { white, green, yellow, red } from "../../theme";
import { updateMoney } from "../../actions";

const Wallet = ({money, updateMoney, history, theme, user}) => {
    const [payments, setPayments] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (user.key) {
            setLoading(true);
            firebase.database().ref(`users/${user.key}/payments`).once("value", snap => {
                setPayments(snap.val() || {})
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            });

            console.log(`users/${user.key}/money`)
            firebase.database().ref(`users/${user.key}/money`).on('value', snap => {
                console.log(snap.val())
                updateMoney(snap.val())
            })
        }
    }, [user.key])


    return <PageContainer style={{overflow: 'auto'}}>
        <LinearGradient>
            <View style={{justifyContent: 'center', alignItems: 'center', flex: 1, margin: "40px 0"}}>
                <Label fontSize={20} color='#FFF'>
                    Meu saldo
                </Label>
                <Label fontSize={32} color={theme.walletBalanceColor} style={{fontWeight: 'bold'}}>{format(money / 100, { code: "BRL" })}</Label>
            </View>
            <View style={{flexDirection: 'row', justifyContent: 'space-between', padding: "0 20px"}}>
                <View>
                    <Button style={{
                        border: `0px solid ${yellow}`,
                        borderRadius: 50,
                        background: 'transparent',
                        padding: '5px 10px',
                        display: 'flex',
                        alignItems: 'center'
                    }} onClick={() => {history.push("/addmoney/data")}}>
                        <Label fontSize={20} color={theme.walletAddText}>Adicionar</Label>
                        <img src={theme.iconAdd} alt="" style={{height: 25, width: 25, marginLeft: 10}} />
                    </Button>
                </View>
                <View>
                    <Button style={{
                        border: '0px solid #fff',
                        borderRadius: 50,
                        background: 'transparent',
                        padding: '5px 10px',
                        display: 'flex',
                        alignItems: 'center'
                    }} onClick={() => {history.push("/rescue/")}}>
                        <Label fontSize={20} color={theme.walletRemoveText}>Retirar</Label>
                        <img src={theme.iconRemove} alt="" style={{height: 25, width: 25, marginLeft: 10}} />
                    </Button>
                </View>
            </View>
        </LinearGradient>
        <View style={{flexDirection: 'column', flex: 1}}>
            <Label style={{margin: '5px 20px 10px'}}>Últimas transações</Label>
            <View style={{ flexDirection: 'column', height: '100%' }}>
                {
                    loading
                        ? <View style={{justifyContent: 'center', alignItems: 'center'}}>
                            <Loading type="spin" width="30px" height="30px" />
                        </View>
                        : ( <Grid data={Object.values(payments || {})} /> )
                }
            </View>
        </View>
    </PageContainer>
}

const mapStateToProps = ({user}) => ({payments: user.payments, theme: user.theme, money: user.money, theme: user.theme, user});

export default connect(mapStateToProps, { updateMoney })(Wallet);
import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import thunk from 'redux-thunk';
import reducer from "../reducers";
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: "turfe" /* key é obrigatorio. */,
    storage /* storage é obrigatorio. */,
    blacklist: [] /* Lista para não persistir. */
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);
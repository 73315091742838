import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';

import PageContainer from "../../components/PageContainer";
import Label from '../../components/Label';
import View from '../../components/View';
import Button from '../../components/Button';
import Input from "../../components/Input/Currency";
import { green, whitePurble, white } from '../../theme';

import { editForm, resetForm } from "../../actions";

const AddMoney = ({history, form, theme, editForm, resetForm}) => {
    const [maskedvalue, setMaskedValue] = useState('');

    const handleSubmit = () => {
        if (!form.value) {
            alert("Insira um valor para ser adicionado");
        } else {
            editForm({
                number: "",
                cvc: "",
                expiry: "",
                name: "",
                phone: ""
            });
            history.push("data")
        }
    }

    const handleGoback = () => {
        resetForm()
        history.goBack();
    }
    
    useEffect(() => {
        console.log(form)
        setMaskedValue(form.value)
    }, [form]);

    return <PageContainer style={{overflow: 'hidden', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
        <View style={{ position: 'absolute', left: 10, top: 10}}>
            <img src={theme.iconExit} alt="" style={{width: 20, height: 20}} onClick={handleGoback} />
        </View>
        <View style={{justifyContent: 'center', alignItems: 'center'}}>
            <Label fontSize={16}>
                Quanto você quer adicionar?
            </Label>
            <Input 
                style={{color: green, border: "none", width: 'fit-content', textAlign: 'center', fontSize: '8vw', height: 'fit-content', backgroundColor: 'inherit'}}
                onChangeEvent={(event, maskedvalue, floatvalue) => {
                    setMaskedValue(maskedvalue);
                    editForm({value: floatvalue});
                }}
                value={maskedvalue}
            />
            <Button style={{background: 'transparent', border: `1px solid ${green}`}} onClick={handleSubmit}>
                <Label color={green}>ADICIONAR DINHEIRO</Label>
            </Button>
        </View>
    </PageContainer>
};

const mapStateToProps = ({form, user}) => ({form, theme: user.theme});

export default connect(mapStateToProps, { editForm, resetForm })(AddMoney);

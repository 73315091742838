import React, { useState } from 'react';
import { connect } from 'react-redux';
import firebase from "firebase";

import PageContainer from "../../components/PageContainer";
import View from "../../components/View";
import Input from "../../components/Input";
import InputPhone from "../../components/Input/Phone";
import InputDate from "../../components/Input/Date";
import Button from "../../components/Button";
import Label from "../../components/Label";
import { whitePurble } from "../../theme";

import { editForm, resetForm, loadUser } from "../../actions";

const Register = ({history, form = {}, editForm, resetForm, loadUser}) => {
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const data = localStorage.getItem('theme');

    const handleSave = async () => {
        try {
            setLoading(true);

            if (loading) {
                return false;
            }

            if (!checked) {
                return false;
            }

            let [day, month, year] = form.birthDay.split(/\//g);
            
            year = parseInt(year)
            month = parseInt(month)
            day = parseInt(day)

            if (year && month && day) {
                if (month <= 0 || month > 12) {
                    alert("Mês inválido");
                    setLoading(false);
                    return false;
                }

                if (day <= 0) {
                    alert("Dia inválido");
                    setLoading(false);
                    return false;
                }
                
                if (year <= 1900 || !year) {
                    alert("Ano inválido");
                    setLoading(false);
                    return false;
                }

                switch (month) {
                    case 1: // Janeiro
                    case 3: // Marco
                    case 5: // Maio
                    case 7: // Julho
                    case 8: // Agosto
                    case 10: // Outubro
                    case 12: // Dezembro
                        if (day > 31) {
                            setLoading(false);
                            alert("Dia inválido");
                            return false;
                        }
                        break;
                    case 2: // Fevereiro
                        if (day > 29) {
                            setLoading(false);
                            alert("Dia inválido");
                            return false;
                        }
                        break;
                    case 4: // Abril
                    case 6: // Junho
                    case 9: // Setembro
                    case 11: // Novembro
                        if (day > 30) {
                            setLoading(false);
                            alert("Dia inválido");
                            return false;
                        }
                        break;
                }

                const birthDay = new Date(year, month - 1, day);

                if (calculateAge(birthDay) < 18) {
                    alert("Para cadastrar-se, é necessário ser maior de 18 anos");
                    setLoading(false);
                    return false;
                }
            } else {
                alert("Data inválida");
                setLoading(false);
                return false;
            }

            console.log(form.email, form.password)
            let birthDay = new Date(year, month - 1, day).getTime();
            console.log(birthDay, year, month, day)

            if(!birthDay || birthDay == NaN) {
                birthDay = 0
            }

            const data = {
                createdAt: Date.now(),
                money: 0,
                status: 1,
                name: form && form.name ? form.name : '',
                surname: form && form.surname ? form.surname : '',
                email: form && form.email ? form.email : '',
                fone: form && form.fone ? form.fone : '',
                birthDay
            }

            console.log(data)
            const {user} = await firebase.auth().createUserWithEmailAndPassword(form.email.trim(), form.password);
            await firebase.database().ref(`users/${user.uid}`).set(data);

            resetForm();
            editForm({});

            await loadUser(user.uid);

            history.push("/home");
            setLoading(false);
        } catch (e) {
            switch(e.code) {
                case "auth/email-already-in-use":
                    alert("E-mail já cadastrado")
                break;
                case "auth/invalid-email":
                    alert("E-mail inválido")
                    break;
                case "auth/operation-not-allowed":
                    alert("E-mail ou senha não são válidos")
                    break;
                case "auth/weak-password":
                    alert("Senha fraca")
                    break;
                default:
                    alert(e.message)
                    break;
            }
            console.log(e.code)
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    const calculateAge = birthday => {
        var ageDifMs = Date.now() - birthday.getTime();
        var ageDate = new Date(ageDifMs);

        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    const handleChange = field => e => {
        editForm({[field]: e.target.value});
    }

    console.log(form)

    return <PageContainer>
        <View style={{position: 'absolute', left: 20, top: 20}}>
            <img
                src={data === 'dark' ? require("../../images/ic_back.png") : require("../../images/ic_back-white.png")}
                alt=""
                style={{width: 20, height: 20}}
                onClick={() => history.push("/login")}
            />
        </View>
        <View style={{alignItems: 'center'}}>
            <img src={data === 'dark' ? require("../../images/logo_splash.png") : require("../../images/logo_turfe-white.png")} style={{
                width: '75vw',
                height: '55vw',
                maxHeight: '300px',
                maxWidth: '300px'
            }} alt="" />
        </View>
        <View style={{alignItems: 'flex-start', paddingLeft: 20}}>
            <Label fontSize={20}>Cadastro</Label>
        </View>
        <View style={{alignItems: 'center', marginBottom: 20}}>
            <Label style={{alignSelf: 'flex-start', paddingLeft: 20}}>Nome</Label>
            <Input value={form.name} onChange={handleChange("name")} />
        </View>
        <View style={{alignItems: 'center', marginBottom: 20}}>
            <Label style={{alignSelf: 'flex-start', paddingLeft: 20}}>Sobrenome</Label>
            <Input value={form.surname} onChange={handleChange("surname")} />
        </View>
        <View style={{alignItems: 'center', marginBottom: 20}}>
            <Label style={{alignSelf: 'flex-start', paddingLeft: 20}}>Data de nascimento</Label>
            <InputDate value={form.birthDay} onChange={handleChange("birthDay")} />
        </View>
        <View style={{alignItems: 'center', marginBottom: 20}}>
            <Label style={{alignSelf: 'flex-start', paddingLeft: 20}}>E-mail</Label>
            <Input value={form.email} onChange={handleChange("email")} type="email" />
        </View>
        <View style={{alignItems: 'center', marginBottom: 20}}>
            <Label style={{alignSelf: 'flex-start', paddingLeft: 20}}>Telefone</Label>
            <InputPhone value={form.fone} onChange={handleChange("fone")} />
        </View>
        <View style={{alignItems: 'center', marginBottom: 20}}>
            <Label style={{alignSelf: 'flex-start', paddingLeft: 20}}>Senha (mínimo de 6 caracteres)</Label>
            <Input value={form.password} onChange={handleChange("password")} type="password"/>
        </View>
        <View style={{alignItems: 'flex-start', marginBottom: 20, flexDirection: 'row', paddingLeft: 20}}>
            <input type="checkbox" style={{height: 15, width: 15}} checked={checked} onClick={() => setChecked(!checked)} />
            <Label
                style={{
                    textDecoration: "underline",
                    fontSize: 14
                }}
                onClick={() => window.open('https://admin.turfeaposta.com/#/polities')}
            >Eu aceito os termos e políticas</Label>
        </View>
        <View style={{alignItems: 'center', marginBottom: 20}}>
            <Button color={checked ? "green" : "grey"} onClick={handleSave}>
                <Label color={data === 'dark' ? '#000' : '#FFF'}>CADASTRAR</Label>
            </Button>
        </View>
    </PageContainer>
};

const mapStateToProps = ({form}) => ({form});

export default connect(mapStateToProps, {editForm, resetForm, loadUser})(Register);

export const returnString = period => (period < 10 ? '0' + period : period);

export const formatDate = timestamp => {
  const obj = new Date(timestamp);

  return `${returnString(obj.getDate())}/${returnString(
    obj.getMonth() + 1,
  )}/${obj.getFullYear()}`;
};

export const formatTime = timestamp => {
  const obj = new Date(timestamp);

  return `${obj.getHours()}:${returnString(obj.getMinutes())}`;
};

export const formatDateTime = timestamp => `${formatDate(timestamp)} ${formatTime(timestamp)}`;

// Opera 8.0+
export const isOpera = (!!global.window.opr && !!global.window.opr.addons) || !!global.window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

// Firefox 1.0+
export const isFirefox = typeof InstallTrigger !== 'undefined';

// Safari 3.0+ "[object HTMLElementConstructor]" 
export const isSafari = /constructor/i.test(global.window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!global.window['safari'] || (typeof global.window.safari !== 'undefined' && global.window.safari.pushNotification));

export const isApple = global.window.navigator.userAgent.match(/iPhone/i) || global.window.navigator.userAgent.match(/iPad/i) || global.window.navigator.userAgent.match(/iPod/i)

// Internet Explorer 6-11
export const isIE = /*@cc_on!@*/false || !!document.documentMode;

// Edge 20+
export const isEdge = !isIE && !!global.window.StyleMedia;

// Chrome 1 - 71
export const isChrome = !!global.window.chrome && (!!global.window.chrome.webstore || !!global.window.chrome.runtime);

// Blink engine detection
export const isBlink = (isChrome || isOpera) && !!global.window.CSS;
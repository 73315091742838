import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import firebase from "firebase";

import PageContainer from "../../components/PageContainer";
import Label from '../../components/Label';
import Header from "../../components/Header/Home";
import View from '../../components/View';
import RowHome from '../../components/Row/Home';
import styled from 'styled-components';

const TitleContainer = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  background-color: ${props => props.theme.theme.homeTitle};
  padding: 10px 20px;
`;

const Home = ({user, location}) => {
    const [bets, setBet] = useState([]);

    const getStatus = ({betAt, betEnd, raceDate}) => {
      const now = new Date().getTime();
    
      if (now < betAt) {
        // Se hoje for antes da data de inicio de apostas
        return 0;
      } else if (now < betEnd && now > betAt) {
        // Se hoje for durante o período de apostas
        return 1;
      } else if (raceDate < now) {
        return 2;
      }
    };    

    useEffect(() => {
      const loadBets = async () => {
        try {
          const obj = (
            await firebase
              .database()
              .ref("bets")
              .once("value")
          ).val();
          let list = [];

          for (const key in obj) {
            if (obj[key].finish) {
              continue;
            }

            const status = getStatus(obj[key]);

            if (status === 1) {
              list = [...list, {...obj[key], key, status}];
            }
          }
  
          setBet(list.sort((a, b) => b.createAt - a.createAt ));
        } catch(e) {
          console.log(e);
          alert("Erro ao carregar as corridas");
        }
      }

      const getToken = async () => {
        // console.log("Looking for")
        if('serviceWorker' in navigator) {
          // console.log("Found")
          const messaging = firebase.messaging();
          
          const registration = await navigator.serviceWorker.register('../../../../firebase-messaging-sw.js');
          // console.log("Service Worker Registered");
          messaging.useServiceWorker(registration);

          const vapidKey = "BPtWTNSolFTAJ3L6qLrNLPcIBCYSeutFbUt1T86iMDh1PqIctef3osLlgvtzOKa29F-YyEq9vwiZA6KCVSDAMeg";
          const token = await messaging.getToken({ vapidKey });
          // console.log("token =>", token);

          await firebase
            .database()
            .ref(`users/${user.key}/token`)
            .push(token);
        }
      }

      loadBets();
      getToken();
    }, []);

    return (
        <PageContainer>
          <TitleContainer>
            <Label>Apostas disponíveis</Label>
          </TitleContainer>
          <View style={{padding: '0 20px', flexDirection: "column", flex: 1, height: '100%'}}>
            <View style={{ flex: 1 }}>
              <View style={{flexDirection: 'column', height: '100%', overflow: 'auto'}}>
                {
                  bets.map(bet => <RowHome {...bet} betKey={bet.key} />)
                }
              </View>
            </View>            
          </View>
        </PageContainer>
    );
};

const mapStateToProps = ({user}) => ({user});

const HeaderProvider = connect(mapStateToProps)(Header);

Home.Header = ({user}) => (
    <HeaderProvider user={user} />
);

export default connect(mapStateToProps)(Home);
import React from 'react';
import styled from "styled-components";
import CurrencyInput from 'react-currency-input'
import { baseStyle } from "./";

const Input = styled(CurrencyInput)`
    ${props => baseStyle(props)}
`;

export default props => <Input {...props} decimalSeparator="," thousandSeparator="." prefix="R$" inputType="tel" />;

import React from 'react';
import styled from "styled-components";
import { whiteBlue, headColor, yellow } from '../../theme';

const SwitcherItem = styled.div`
    padding: 5px 0;
    flex: 1;
    display: flex;
    justify-content: center;

    span {
        color: ${yellow};
    }
`;

const BaseSwitcher = styled.div`
    border-radius: 50px;
    border: 1px solid ${props => props.borderColor || yellow};
    width: 75vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    
    ${SwitcherItem}:first-child {
        border-radius: 50px 0 0 50px;
    }

    ${SwitcherItem}:last-child {
        border-radius: 0 50px 50px 0;
    }

    ${SwitcherItem}:nth-child(${props => props.step + 1 || 1}) {
        background-color: ${yellow};
        
        span {
            color: ${headColor};
        }
    }
`;


const Switcher = ({setStep, ...props}) => {

    return <BaseSwitcher {...props}>
        {props.children.map((item, index) => (
            <SwitcherItem onClick={() => setStep(index)}>
                {item}
            </SwitcherItem>
        ))}
    </BaseSwitcher>
};

export default Switcher;

import React, { useEffect } from "react";
import { format } from "currency-formatter";
import firebase from "firebase";

import BaseHeader from "./";
import View from "../View";
import Label from "../Label";
import { whiteBlue, whitePurble, green, yellow } from "../../theme";
import { useLocation, useHistory } from "react-router-dom";
import { updateMoney } from "../../actions";
import { connect } from "react-redux";

const Header = ({theme = {}, user, uid, updateMoney}) => {
    const location = useLocation();
    const history = useHistory();
    const isHome = ["/home", "/"].includes(location.pathname);

    useEffect(() => {
        console.log(uid)
        firebase.database().ref(`users/${uid}/money`).on('value', snap => {
            updateMoney(snap.val())
        })
    }, [])

    console.log(user, uid)
    
    return (
        <BaseHeader>
            <View style={{justifyContent: "space-between", flexDirection: 'row', marginBottom: 10}}>
                {isHome ? <div /> : <img src={require("../../images/ic_back.png")} alt="" style={{width: 20, height: 20}} onClick={history.goBack} />}
                <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 10}} onClick={() => history.push("/addmoney/")}>
                    <Label color="#ffca28">Adicionar saldo</Label>
                    <img src={require('../../images/0 ic_add_yellow.png')} alt="" style={{width: 20, height: 20}} />
                </View>
            </View>
            <View style={{justifyContent: "flex-end", alignItems: 'center', flexDirection: 'row'}}>
                {/* <Label style={{ marginRight: 4 }} fontSize={20} color={whitePurble}>Meu saldo</Label>
                <Label fontSize={18} color={green}>{format(user.money/100, { code: "BRL" })}</Label> */}
                <View style={{justifyContent: "flex-start", alignItems: 'center', flexDirection: 'row'}}>
                    <Label style={{ marginRight: 4 }} fontSize={20} color="#FFF">Meu saldo</Label>
                    <Label fontSize={18} color={theme.homeValue}>{format(user.money/100, { code: "BRL" })}</Label>
                </View>
{/* 
                <View>
                    <img

                    />
                </View> */}
            </View>
        </BaseHeader>
    )
}

const mapStateToProps = ({user}) => ({theme: user.theme, money: user.money, uid: user.key});

export default connect(mapStateToProps, { updateMoney })(Header);
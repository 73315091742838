import React, { useEffect } from 'react';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import { BrowserRouter as Router } from "react-router-dom";

import Routes from "./routes";
import {store, persistor} from "./store";

import firebase from "firebase";

import ThemeApp from './theme/App';

const firebaseConfig = {
  apiKey: "AIzaSyBnpvAndk_mTkPeNANHL4AiCf9xSJMP4Mo",
  authDomain: "jockeybet-d7337.firebaseapp.com",
  databaseURL: "https://jockeybet-d7337.firebaseio.com",
  projectId: "jockeybet-d7337",
  storageBucket: "jockeybet-d7337.appspot.com",
  messagingSenderId: "789238774572",
  appId: "1:789238774572:web:40892fbdbdcd14fb508a89"
};

function App() {
  useEffect(() => {
    firebase.initializeApp(firebaseConfig);
  }, [])

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeApp>
          <Router>
            <Routes />
          </Router>
        </ThemeApp>
      </PersistGate>
    </Provider>
  );
}

export default App;

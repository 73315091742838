import React, { useState } from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import firebase from "firebase";
import Loading from "react-loading";

import { whiteBlue } from '../../theme';

import PageContainer from "../../components/PageContainer";
import View from "../../components/View";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Label from "../../components/Label";


import { loadUser } from "../../actions";

const Login = ({loadUser}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const data = localStorage.getItem('theme');

    const login = async () => {
        try {
            setLoading(true);
            const { user } = await firebase.auth().signInWithEmailAndPassword(email.trim(), password);
            
            const snap = await firebase.database().ref(`users/${user.uid}/status`).once('value');
            const userStatus = snap.val();

            console.log('User status =>', userStatus)

            if (userStatus === 0) {
                alert('Usuário desabilitado');
                setLoading(false);
                return false;
            } else if (!userStatus) {
                alert('Usuário não encontrado');
                setLoading(false);
                return false;
            }

            const res = await loadUser(user.uid);

            if (res === true) {
                history.push("/home")
            } else {
                alert(res);
                setLoading(false);
            }
        } catch (e) {
            switch(e.code) {
                case "auth/invalid-email":
                    alert("E-mail inválido");
                    break;
                case "auth/user-disabled":
                    alert("Usuário desabilitado");
                    break;
                case "auth/user-not-found":
                    alert("Usuário não encontrado");
                    break;
                case "auth/wrong-password":
                    alert("Senha inválido");
                    break;
                default:
                    alert(e.message)
                    break;
            }
            setLoading(false);
        }
    }

    return (
        <PageContainer style={{alignItens: 'center', height: '100%'}}>
            <View style={{alignItems: 'center'}}>
                <img src={data === 'dark' ? require("../../images/logo_splash.png") : require("../../images/logo_turfe-white.png")} style={{
                    width: '75vw',
                    height: '55vw',
                    maxHeight: '300px',
                    maxWidth: '300px'
                }} alt="" />
            </View>
            <View style={{alignItems: 'center', marginBottom: 20}}>
                <Input value={email} onChange={e => setEmail(e.target.value)} placeholder="E-mail" type="email" />
            </View>
            <View style={{alignItems: 'center', marginBottom: 20}}>
                <Input value={password} onChange={e => setPassword(e.target.value)} placeholder="Senha" type="password" />
                <Label onClick={() => history.push("/resetpassword")} style={{cursor: "pointer", alignSelf: 'flex-end', fontSize: 14, marginRight: 20, marginTop: 5}}>Esqueceu a senha?</Label>
            </View>
            <View style={{alignItems: 'center', marginBottom: 20}}>
                <Button color="green" onClick={login}>
                    {
                        loading ? <View>
                            <Loading type="spin" width="30px" height="30px" />
                        </View>
                        : <Label color={data === 'dark' ? '#000' : '#FFF'}>ENTRAR</Label>
                    }
                </Button>
            </View>
            <View style={{alignItems: 'center', marginBottom: 20}}>
                <Label>Não possui uma conta?</Label>
            </View>
            <View style={{alignItems: 'center', marginBottom: 20}}>
                <Button color="white" onClick={() => history.push("/register")}>
                    <Label color="#345A33">CRIAR UMA CONTA</Label>
                </Button>
            </View>
        </PageContainer>
    )
};

export default connect(null, {loadUser})(Login);

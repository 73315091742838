export const LOGIN_USER = "LOGIN_USER";
export const LOAD_USER = "LOAD_USER";
export const LOGOFF_USER = "LOGOFF_USER";
export const CHANGE_THEME = "CHANGE_THEME";

export const ADD_MONEY = "ADD_MONEY";
export const RESCUE_MONEY = "RESCUE_MONEY";
export const UPDATE_MONEY = "UPDATE_MONEY";

export const LOAD_MY_BETS = "LOAD_MY_BETS";
export const UPDATE_PAYMENTS = "UPDATE_PAYMENTS";

export const UPDATE_HORSE = "UPDATE_HORSE";
export const FINISH_BET = "FINISH_BET";
export const LOAD_BETS = "LOAD_BETS";
export const JOIN_BET = "JOIN_BET";

export const EDIT_FORM = "EDIT_FORM";
export const RESET_FORM = "RESET_FORM";

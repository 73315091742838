import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import firebase from 'firebase';
import Loading from "react-loading";

import PageContainer from "../../components/PageContainer";
import Header from "../../components/Header/MyBets";
import View from '../../components/View';
import Grid from "../../components/grid/myBets";

const MyBets = ({user, theme}) => {
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0);
    const [myBets, setBet] = useState([]);
    const currentUser = firebase.auth().currentUser;
    
    useEffect(() => {
        console.clear()
        console.log('Alo', currentUser, user)
        if (user) {
            setLoading(true)
            Promise.all([
                firebase.database().ref(`bets`).once("value"),
                // firebase.database().ref(`userBets/J0Nq1y07CrP4N2cqcehevacBO5f2`).once("value")
                firebase.database().ref(`userBets/${user.key}`).once("value")
            ]).then(([betSnap, myBetSnap]) => {
                const betObj = betSnap.val();
                const myBetObj = myBetSnap.val();
                let openBets = [];
                let closeBets = [];
                console.log(myBetObj)
                for (const betKey in myBetObj) {                    
                    if (betObj[betKey].finish) {
                        myBetObj[betKey].finish = betObj[betKey].finish;
                        
                        closeBets = [...closeBets, myBetObj[betKey]];
                    } else {
                        openBets = [...openBets, myBetObj[betKey]];
                    }                
                }

                setBet([openBets,closeBets]);
                setLoading(false);
            })
        }
    }, [user]);

    if (loading) {
        return <PageContainer style={{justifyContent: 'center', alignItems: 'center'}}>
            <Loading type="spin" width="30px" height="30px" />
        </PageContainer>
    }

    return <PageContainer>
        <Header step={step} setStep={setStep} />
        
        <View style={{ overflow: 'auto', height: '100%', '-webkit-overflow-scrolling': 'auto' }}>
            {
                myBets.length > 0 && myBets[step].length > 0
                    ? step === 0 
                        ? (
                            <View>
                                <Grid data={myBets[0]}/>
                            </View>
                        ) 
                        : (
                            <View>
                                <Grid data={myBets[1]} status/>
                            </View>
                        )
                    : (
                        <View style={{paddingTop: 10, alignItems: 'center'}}>
                            <img
                                alt=""
                                src={theme.emptyState}
                                style={{
                                    width: '75vw',
                                    height: '55vw'
                                }}
                            />
                        </View>
                    )
            }
        </View>
    </PageContainer>
};

const mapStateToProps = ({user}) => ({user, theme: user.theme});

export default connect(mapStateToProps)(MyBets);

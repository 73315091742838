import React from 'react';
import styled from "styled-components";

import { headColor } from '../../theme';

const Bar = styled.div`
    width: 100vw;
    background: ${props => props.theme.theme.headColor};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
`;

const Title = styled.div`
    color: #FFF;

`;

const Header = props => (
    <Bar {...props}>
        <Title>
            TURFE APOSTA
        </Title>
        {props.children}
    </Bar>
);

export default Header;

import React from 'react';
import PageContainer from '../../components/PageContainer';
import View from '../../components/View';
import Label from '../../components/Label';
import Button from '../../components/Button';
import { whiteBlue } from '../../theme';
import { useHistory } from 'react-router-dom';

const ConfirmBetDone = () => {
    const history = useHistory();

    return <PageContainer>
        <View style={{alignItems: "center", justifyContent: 'center', height: '100vh'}}>
            <img src={require("../../images/img_confirmation.png")} alt="" style={{width: "30vw"}} />
            <Label style={{margin: "20px 0", fontSize: 20}}>Aposta realizada</Label>
            <Button 
                style={{width: '50vw', background: 'transparent', border: `1px solid ${whiteBlue}`}}
                onClick={() => {
                    history.push("/home")
                }}
            >
                <Label style={{color: whiteBlue}}>FECHAR</Label>
            </Button>
        </View>
    </PageContainer>
};

export default ConfirmBetDone;

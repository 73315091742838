import React from "react";
import styled, { css } from "styled-components";
import PropType from "prop-types";
import { whiteBlue, white, green, grey } from "../../theme";

const ButtonBase = styled.button`
    width: calc(100% - 40px);
    height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 500ms ease-in-out;
    border: none;

    :focus {
        outline: #93a3d5;
    }

    span {
        font-size: ${props => props.fontSize + 'px'}
    }
`;

const getBGColor = color => {
    switch(color) {
        case "whiteBlue":
            return whiteBlue;
        case "green":
            return "#345A33";
        case "grey":
            return grey;
        case "white":
        default:
            return white;
    }
}

const Button = ({children, color, fontSize = 14, ...rest}) => <ButtonBase {...rest} fontSize={fontSize} style={{
    background: getBGColor(color),
    borderColor: getBGColor(color),
    ...rest.style
}} >{children}</ButtonBase>;

Button.prototype = {
    style: css,
    color: PropType.oneOf(["whiteBlue", "white", "green", "grey"]),
    fontSize: PropType.number
};

export default Button;
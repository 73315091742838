export default {
    bodyColor: "#FFF",
    headColor: '#345A33',
    footColor: '#F0f0F0',
    inputColor: '#F6F6F6',
    homeTitle: '#F6F6F6',
    homeValue: '#FFF',
    homeRowBorderColor: '#D8D8D8',
    color: "#262626",
    gradient: 'linear-gradient(0deg, #498F47, #345A33)',
    walletAddText: '#FFF',
    walletRemoveText: '#FFF',
    walletBalanceColor: '#FFF',



    iconAdd: require('../images/0 ic_add_white.png'),
    iconRemove: require('../images/ic_retirar_white.png'),
    iconBack: require("../images/ic_back-white.png"),
    iconExit: require("../images/ic_close-white.png"),
    emptyState: require("../images/img_noresults-white.png"),
    logo: require("../images/logo_turfe-white.png"),
    AVAlogo: require("../images/avamobile_black.png"),
}
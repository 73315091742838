import React from 'react';
import styled from "styled-components";

import { whitePurble, grey, yellow, green } from '../../theme';
import { isSafari, isApple } from '../../utils';

import Label from '../Label';
import View from '../View';
import { formatDate } from '../../utils';
import { useHistory } from 'react-router-dom';

const RowBase = styled.div`
    display: flex;
    margin-bottom: 5px;
    // padding: ${isSafari && isApple ? '0' : '10px 0' };
    height: fit-content;
    padding: 10px 0;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid ${props => props.theme.theme.homeRowBorderColor};
    flex-shrink: 0;

    & > ${View} {
        flex-direction: column;


        ${Label} {
            font-size: 14px;
        }
    }
`;

const Row = ({name, status, betAt, betKey}) => {
    const history = useHistory();
    
    const gotoBet = () => {
        history.push(`bet/${betKey}/`);
    }

    return <RowBase onClick={gotoBet}>
        <View style={{border: '0px solid #F51', alignItems: 'flex-start', justifyContent: 'center'}}>
            <Label style={{fontSize: 16, marginBottom: isSafari && isApple ? -5 : 0}}>{name}</Label>
            <Label style={{color: grey}}>
                {status === 0 ? `Disponível em ${formatDate(betAt)}` : null}
                {/* {status === 1 ? "Aberto para apostas" : null} */}
                {status === 2 ? "Apostas finalizadas" : null}
            </Label>
        </View>
        <View style={{justifyContent: 'center'}}>
            <Label
                style={{color: green}}
            >
                APOSTAR
            </Label>
        </View>
    </RowBase>
};

export default Row;

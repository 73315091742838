import React from 'react';
import View from '../../View';
import Label from '../../Label';
import { formatDateTime } from '../../../utils';
import { format } from 'currency-formatter';
import { yellow, red, green, headColor } from '../../../theme';
import { isSafari } from '../../../utils';
import styled from 'styled-components';

// import { Container } from './styles';

const returnMessage = type => {
    switch (type) {
        case 0: // Retirar
            return 'Solicitação de retirada';
        case 1: // Depositar
            return 'Adicionado crédito';
        case 2: // Ganhou a aposta
            return 'Ganhou aposta';
        case 3: // Apostou
            return 'Apostou';
        case 4:
            return 'Reembolso';
        case 5:
            return 'Crédito adicionado';
        case 6:
            return 'Foi retirado';
    }
};

const returnStatusColor = type => {
    switch (type) {
        case 0: // Retirar
        case 6: // Adimin retirou
            return red;
        case 1: // Depositar
        case 2: // Ganhou a aposta
        case 4: // Reembolso
        case 5: // Crédito adicionado
            return green;
        case 3: // Apostou
            return yellow;
    }
};

const RowContainer = styled.div`
    display: flex;
    align-self: stretch;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 10px;
    flex-shrink: 0;
    border-bottom: 1px solid ${props => props.theme.theme.homeRowBorderColor};
    background: ${props => props.theme.theme.bodyColor};

    // & > ${View} {
    //     color: ${props => props.theme.theme.color};
    // }
`;

const Row = ({type, createdAt, amount}) => (
    <RowContainer>
        <Label fontUnit="rem" fontSize={0.8}>
            {returnMessage(type)} - {formatDateTime(createdAt)}
        </Label>
        <Label fontUnit="rem" fontSize={0.8} color={returnStatusColor(type)}>
            {type === 0 || type === 3 ? "-" : null}
            {format(amount/100, {code: "BRL"})}
        </Label>
    </RowContainer>
)

export default ({data}) => (
    <View style={{flexDirection: 'column', overflow: 'auto', height: '100%'}}>
        {
            data.sort((a, b) => b.createdAt - a.createdAt).map(item => <Row {...item} /> )
        }
    </View>
);


import React, { useState } from 'react';
import { connect } from "react-redux";
import Loading from "react-loading";

import PageContainer from "../../components/PageContainer";
import Label from '../../components/Label';
import View from '../../components/View';
import Input from '../../components/Input';
import InputCPF from '../../components/Input/CPF';
import Select from '../../components/Input/Select';
import { inputContainerColor, red } from '../../theme';

import { rescueMoney, editForm } from "../../actions";

const bankList = [
    {key: "001", name: "001 - Banco do Brasil"},
    {key: "033", name: "033 - Banco Santander"},
    {key: "237", name: "237 - Banco Bradesco"},
    {key: "422", name: "422 - Banco Safra"},
    {key: "074", name: "074 - Banco J. Safra"},
    {key: "077", name: "077 - Banco Inter"},
    {key: "104", name: "104 - Caixa Econômica Federal"},
    {key: "341", name: "341 - Banco Itaú"},
    {key: "318", name: "318 - Banco BMG"},
    {key: "399", name: "399 - HSBC Bank Brasil"},
    {key: "745", name: "745 - Banco Citibank S.A."},
    {key: "477", name: "477 - Citibank N.A."},
    {key: "748", name: "748 - Banco Cooperativo Sicredi"},
    {key: "260", name: "260 - Nu Pagamentos"},
];

const RescueData = ({history, form, rescueMoney, editForm, theme}) => {
    const [loading, setLoading] = useState(false);

    const handleChange = field => e => {
        editForm({[field]: e.target.value});
    }

    const handleSend = async () => {
        try {
            if (loading) {
                return false;
            }
            setLoading(true);
            
            if (!form.name || !form.cpf || !form.bank || !form.agency || !form.account || !form.digit) {
                alert("Há campos vazios no formulário!");
                setLoading(false)
            } else {
                const bankValue = bankList.find(bank => parseInt(form.bank, 10) === parseInt(bank.key, 10)) || bankList[0]

                console.log('form =>', form)
                console.log(bankList, bankValue)

                if (!bankValue || !bankValue.name) {
                    alert('Banco não encontrado')
                    setLoading(false)
                    return false;
                }

                await rescueMoney(form.value * 100, form.name, form.cpf, bankValue.name, form.agency.replace(/[\.|\-|\,]/g, ""), form.account.replace(/[\.|\-|\,]/g, ""), form.digit);
                history.push("done");
            }
        } catch(e) {
            alert('Erro ao retirar dinheiro')
            setLoading(false)
            console.log(e)
        }
    }

    return <PageContainer style={{justifyContent: "space-between", height: '100%'}}>
        <View>
            <View style={{alignSelf: 'flex-start', flexShrink: 0}}>
                <img src={require("../../images/ic_x.png")} alt="" style={{margin: 20, width: 20, height: 20}} onClick={history.goBack} />
            </View>
            <View style={{padding: 20, alignItems: 'center', overflow: 'auto'}}>
                <View style={{ flexShrink: 0 }}>
                    <Label style={{marginBottom: 5}}>Nome</Label>
                    <Input
                        value={form.name}
                        onChange={handleChange('name')}
                        style={{
                            marginBottom: 10,
                            // background: inputContainerColor,
                            border: "none"
                        }}
                        width="100%"
                        placeholder="Nome"
                    />
                </View>
                <View style={{ flexShrink: 0 }}>
                    <Label style={{marginBottom: 5}}>CPF</Label>
                    <InputCPF
                        value={form.cpf}
                        onChange={handleChange('cpf')}
                        style={{
                            marginBottom: 10,
                            // background: inputContainerColor,
                            border: "none"
                        }}
                        width="100%"
                        placeholder="XXX.XXX.XXX-XX"
                    />
                </View>
                <View style={{ flexShrink: 0 }}>
                    <Label style={{marginBottom: 5}}>Banco</Label>
                    <Select
                        value={form.bank}
                        onChange={handleChange('bank')}
                        style={{
                            marginBottom: 10,
                            // background: inputContainerColor,
                            border: "none"
                        }}
                        width="100%"
                    >
                        {
                            bankList.map(bank => (
                                <option value={bank.key} label={bank.name} style={{color: "#f51"}} />
                            ))
                        }
                    </Select>
                </View>
                <View style={{ flexShrink: 0 }}>
                    <Label style={{marginBottom: 5}}>Agência</Label>
                    <Input
                        value={form.agency}
                        onChange={handleChange('agency')}
                        style={{
                            marginBottom: 10,
                            // background: inputContainerColor,
                            border: "none"
                        }}
                        width="100%"
                        type="tel"
                        placeholder="Agência"
                    />
                </View>
                <View style={{ flexShrink: 0, flexDirection: 'row' }}>
                    <View style={{ flex: 3, marginRight: 10 }}>
                        <Label style={{marginBottom: 5}}>Conta</Label>
                        <Input
                            value={form.account}
                            onChange={handleChange('account')}
                            style={{
                                marginBottom: 10,
                                // background: inputContainerColor,
                                border: "none"
                            }}
                            width="100%"
                            type="tel"
                            placeholder="Conta"
                        />
                    </View>

                    <View style={{ flex: 1, flexShrink: 0 }}>
                        <Label style={{marginBottom: 5}}>Dígito</Label>
                        <Input
                            value={form.digit}
                            onChange={handleChange('digit')}
                            style={{
                                marginBottom: 10,
                                // background: inputContainerColor,
                                border: "none"
                            }}
                            width="100%"
                            type="tel"
                            placeholder="000"
                        />
                    </View>
                </View>
            </View>
        </View>
        <View style={{background: red, padding: '15px 0 25px 0'}} onClick={handleSend}>
            {
                loading ? (
                    <View style={{alignSelf: 'center'}}>
                        <Loading type="spin" height="30px" width="30px" />
                    </View>
                ) : <Label color={theme.bodyColor} style={{textAlign: 'center'}}>ENVIAR REQUISIÇÃO</Label>
            }
        </View>
    </PageContainer>
};

const mapStateToProps = ({form, user}) => ({form, theme: user.theme});

export default connect(mapStateToProps, { rescueMoney, editForm })(RescueData);

import React from "react";

import BaseHeader from "./";
import View from "../View";
import Label from "../Label";
import { whiteBlue, yellow } from "../../theme";
import Switcher from "../Switcher";

const Header = ({user, step, setStep}) => {
    return (
        <BaseHeader>
            <View style={{alignItems: "center", marginBottom: 10}}>
                <Label color={yellow}>Minhas apostas</Label>
            </View>
            <Switcher step={step} setStep={setStep}>
                <Label>Abertas</Label>
                <Label>Finalizadas</Label>
            </Switcher>
        </BaseHeader>
    )
}

export default Header;
import React from 'react';
import styled from "styled-components";
import InputMask from 'react-input-mask';
import { baseStyle } from "./";

const InputBase = styled(InputMask)`${props => baseStyle(props)}`;

export default ({onChange = () => {}, ...props}) => {
    const handleChangeExpiry = (e) => {
        const expiry = e.target.value.replace(/[\*|\/]/g, "");
        const length = expiry.length;
        let value = parseInt(expiry, 10);
        let newValue;

        if (length === 1 && expiry > 1) {
            newValue = '0' + expiry;
        } else if (length === 2 && value > 12) {
            newValue = '12';
        } else {
            newValue = expiry;
        }

        onChange({target: {value: newValue}});
    };

    return (
        <InputBase
            mask="99/99"
            maskChar="*"
            placeholder="MM/YY"
            {...props}
            type="tel" 
            onChange={handleChangeExpiry}
        />
    )
}

import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import CreditCard from 'react-credit-cards';
import Loading from "react-loading";

import PageContainer from "../../components/PageContainer";
import Label from '../../components/Label';
import View from '../../components/View';
import Input from '../../components/Input';
import InputCPF from '../../components/Input/CPF';
import InputPhone from '../../components/Input/Phone';
import InputCardNumber from "../../components/Input/CardNumber";
import InputExpiry from "../../components/Input/Expiry";
import Select from '../../components/Input/Select';

import { green, inputContainerColor } from "../../theme";

import { editForm, addMoney } from "../../actions";
import Axios from 'axios';

const handleErrorMsg = code => {
    switch(code) {
        case "05":
            return "Não foi possível processar a transação. Questão relacionada a segurança, inadimplencia ou limite do portador.";
        case "57":
            return "Transação não permitida para o cartão.";
        case "70":
            return "Limite excedido/sem saldo.";
        case "75":
            return "Sua Transação não pode ser processada.";
        case "77":
            return "Não foi localizado a transação original.";
        case "78":
            return "Cartão bloqueado, solicite o desbloqueio do cartão";
        case "99":
            return "Tente mais tarde.";
        default:
            return "Erro não esperado, entre em contado com o administrador";
    }
}

const AddMoneyData = ({history, form, editForm, addMoney, theme}) => {
    const [focus, setFocus] = useState("");
    const [loading, setLoading] = useState(false);

    const handleEdit = field => e => {
        editForm({[field]: e.target.value});
    }

    const handleAddMoney = async () => {
        try {
            if (loading) {
                return false;
            }

            window.open('https://wa.me/551138685253', '_blank')




            return

            if (!form.name || !form.email || !form.cpf || !form.cvc || !form.expiry || !form.number) {
                alert("Preencha todos os dados para realizar o pagamento");
                return false;
            }

            setLoading(true);

            const res = await addMoney(
                form.name,
                form.email,
                form.cpf,
                form.cvc,
                form.expiry,
                form.number,
                parseFloat(form.value) * 100
            );

            if (res === true) {
                history.push("done");
            } else {
                alert(handleErrorMsg(res));
                setLoading(false);
            }

        } catch(e) {
            console.log(e)
            setLoading(false);
        }
    }

    console.log(form)

    return <PageContainer style={{justifyContent: "space-between", height: '100%'}}>
        <View>
            <View style={{flexShrink: 0}}>
                <img src={require("../../images/ic_x.png")} alt="" style={{margin: 20, width: 20, height: 20}} onClick={history.goBack} />
            </View>
            <View style={{padding: '0 20px', overflow: 'auto'}}>
                <Label><b>PIX Jockey Club de Sorocaba:</b></Label>
                <Label>61.374.690/0001-33</Label>
                <Label style={{marginTop: 15, marginBottom: 15}}>OU</Label>
                <Label><b>DADOS PARA DEPÓSITO</b></Label>
                <Label style={{marginTop: 15}}>Banco Bradesco</Label>
                <Label>Ag: 1008-1</Label>
                <Label>Cc: 24181-4</Label>
                <Label style={{marginTop: 15}}>Banco Itaú</Label>
                <Label>Ag: 6468</Label>
                <Label>Cc: 25333-8</Label>
                <Label style={{marginTop: 15}}>Jockey Club de Sorocaba </Label>
                <Label>CNPJ: 61.374.690/0001-33</Label>
                <Label style={{marginTop: 15}}><b>Importante: Enviar Comprovante para o WhatsApp (11) 3868-5253</b></Label>
            </View>

            {/*<View style={{alignItems: 'center', padding: '0 20px', overflow: 'auto'}}>
            
                <View style={{flexShrink: 0}}>
                    <CreditCard
                        cvc={form.cvc}
                        expiry={form.expiry}
                        focused={focus}
                        name={form.name}
                        number={form.number}
                    />
                </View>

                <View style={{alignItems: 'flex-start', marginTop: 15, flexShrink: 0}}>
                    <Label>Número do cartão</Label>
                    <InputCardNumber
                        value={form.number}
                        onChange={handleEdit("number")}
                        onFocus={() => setFocus('number')}
                        onBlur={() => setFocus('')}
                        style={{
                            // background: inputContainerColor,
                            border: "none",
                            marginBottom: 10
                        }}
                        width='100%'
                    />
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexShrink: 0 }}>
                    <View style={{alignItems: 'flex-start', width: 'calc(50% - 10px)'}}>
                        <Label>Código de segurança</Label>
                        <Input
                            value={form.cvc}
                            onChange={handleEdit("cvc")}
                            onFocus={() => setFocus('cvc')}
                            onBlur={() => setFocus('')}
                            style={{
                                // background: inputContainerColor,
                                border: "none",
                                marginBottom: 10
                            }}
                            width='100%'
                        />
                    </View>
                    <View style={{alignItems: 'flex-start', width: 'calc(50% - 10px)', flexShrink: 0}}>
                        <Label>Data de validade</Label>
                        <InputExpiry
                            value={form.expiry}
                            onChange={handleEdit("expiry")}
                            onFocus={() => setFocus('expiry')}
                            onBlur={() => setFocus('')}
                            style={{
                                // background: inputContainerColor,
                                border: "none",
                                marginBottom: 10
                            }}
                            width='100%'
                        />
                    </View>
                </View>
                <View style={{alignItems: 'flex-start', flexShrink: 0}}>
                    <Label>Nome</Label>
                    <Input
                        value={form.name}
                        onChange={handleEdit("name")}
                        onFocus={() => setFocus('name')}
                        onBlur={() => setFocus('')}
                        style={{
                            // background: inputContainerColor,
                            border: "none",
                            marginBottom: 10
                        }}
                        width='100%'
                    />
                </View>
                <View style={{alignItems: 'flex-start', flexShrink: 0}}>
                    <Label>CPF</Label>
                    <InputCPF
                        value={form.cpf}
                        onChange={handleEdit("cpf")}
                        style={{
                            // background: inputContainerColor,
                            border: "none",
                            marginBottom: 10
                        }}
                        width='100%'
                        placeholder="XXX.XXX.XXX-XX"
                    />
                </View>
                <View style={{alignItems: 'flex-start', flexShrink: 0}}>
                    <Label>E-mail</Label>
                    <Input
                        value={form.email}
                        onChange={handleEdit("email")}
                        style={{
                            // background: inputContainerColor,
                            border: "none",
                            marginBottom: 10
                        }}
                        width='100%'
                        type="email"
                    />
                </View>
            </View>*/}
        </View>
        <View style={{background: green, padding: '15px 0 25px 0'}} onClick={handleAddMoney}>
            {
                /*loading ? (
                    <View style={{alignSelf: 'center'}}>
                        <Loading type="spin" height="30px" width="30px" />
                    </View>
                ) : <Label color={theme.bodyColor} style={{textAlign: 'center'}}>CONCLUIR PAGAMENTO</Label>*/
                loading ? (
                    <View style={{alignSelf: 'center'}}>
                        <Loading type="spin" height="30px" width="30px" />
                    </View>
                ) : <Label color={theme.bodyColor} style={{textAlign: 'center'}}>ENVIAR COMPROVANTE</Label>
            }
        </View>
    </PageContainer>
};

const mapStateToProps = ({form, user}) => ({form, theme: user.theme});

export default connect(mapStateToProps, { editForm, addMoney })(AddMoneyData);

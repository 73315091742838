import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';

import PageContainer from "../../components/PageContainer";
import Label from '../../components/Label';
import View from '../../components/View';
import Button from '../../components/Button';
import Input from "../../components/Input/Currency";
import { red, whitePurble } from '../../theme';

import { editForm, resetForm } from "../../actions";

const Rescue = ({history, money, form, theme, editForm, resetForm}) => {
    const [maskedvalue, setMaskedValue] = useState('');

    const handleSubmit = () => {
        if (!form.value) {
            alert("Insira um valor para ser retirado");
        } else if (money < form.value * 100) {
            alert("Você só pode retirar o quanto você tem em sua carteira");
        } else {
            history.push("data")
        }
    }

    const handleGoback = () => {
        resetForm()
        history.goBack();
    }
    
    useEffect(() => {
        setMaskedValue(form.value)
    }, [form]);

    return <PageContainer style={{overflow: 'hidden', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
        <View style={{ position: 'absolute', left: 10, top: 10}}>
            <img src={theme.iconExit} alt="" style={{margin: 20, width: 20, height: 20}} onClick={handleGoback} />
        </View>
        <View style={{justifyContent: 'center', alignItems: 'center'}}>
            <Label fontSize={16}>
                Quanto você quer retirar?
            </Label>
            <Input 
                style={{color: red, border: "none", width: 'fit-content', textAlign: 'center', fontSize: '8vw', height: 'fit-content', backgroundColor: 'inherit'}}
                onChangeEvent={(event, maskedvalue, floatvalue) => {
                    setMaskedValue(maskedvalue);
                    editForm({value: floatvalue, bank: 237});
                }}
                value={maskedvalue}
            />
            <Button style={{background: 'transparent', border: `1px solid ${red}`}} onClick={handleSubmit}>
                <Label color={red}>RETIRAR DINHEIRO</Label>
            </Button>
        </View>
    </PageContainer>
};

const mapStateToProps = ({user, form}) => ({theme: user.theme, money: user.money, form});

export default connect(mapStateToProps, { editForm, resetForm })(Rescue);

import React from 'react';
import styled from 'styled-components';
import { baseStyle } from "./";
import { Form } from "react-bootstrap";

export const SelectBase = ({children, ...props}) => (
    <Form>
        <Form.Group>
            <Form.Control as="select" {...props}>
                {children.map(item => <option {...item.props}>{item.props.label}</option>)}
            </Form.Control>
        </Form.Group>
    </Form>
);

export default styled(SelectBase)`${props => baseStyle(props)}`;
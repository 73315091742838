import styled from "styled-components";

const LinearGradient = styled.div`
    background: ${props => props.theme.theme.gradient};
    padding: 10px;
    display: flex;
    align-self: stretch;
    flex-direction: column;
`;

export default LinearGradient
import React from 'react';
import { connect } from "react-redux";
import firebase from "firebase";
import Switch from 'react-ios-switch';

import {version} from '../../../package.json';

import PageContainer from "../../components/PageContainer";
import Label from '../../components/Label';
import View from '../../components/View';
import { headColor, red } from '../../theme';
import dark from '../../theme/dark';

import { logoff, changeTheme } from "../../actions";
import light from '../../theme/light';

const More = ({history, theme, user, logoff, changeTheme}) => {
    const handleExit = async () => {
        try {
            await firebase.auth().signOut();

            logoff();

            history.push("/login");

        } catch(e) {
            console.log(e);
            alert("Ocorreu algum erro!");
        }
    }

    const handleOpenAVA = () => {
        const link = `http://www.avamobile.com.br/`;

        window.open(link, '__blank')
    }

    const handleChangeTheme = checked => {
        console.log(checked)
        changeTheme(!checked ? light : dark);
        const data = !checked ? 'light' : 'dark'
        if (user.key) {
            firebase.database().ref(`users/${user.key}/theme`).set(data)
        }
        localStorage.setItem('theme', data)
    }

    console.log('Theme =>', theme)
    console.log('User =>', user)

    return <PageContainer>
        
        <View style={{alignItems: "center"}}>
            <img
                src={theme.logo}
                alt=""
                style={{
                    width: '75vw',
                    height: '55vw',
                    maxHeight: '300px',
                    maxWidth: '300px'
                }}
            />
        </View>
        <View>
            <View style={{background: theme.bodyColor, alignItems: 'center', padding: 10, marginBottom: 1}} onClick={() => window.open('https://admin.turfeaposta.com/#/regularization')}>
                <Label style={{fontSize: '4vw', fontWeight: 'bold'}}>Regulamento</Label>
            </View>

            <View style={{background: theme.bodyColor, alignItems: 'center', padding: 10, marginBottom: 1}} onClick={() => window.open('https://admin.turfeaposta.com/#/about')}>
                <Label style={{fontSize: '4vw', fontWeight: 'bold'}}>Quem somos?</Label>
            </View>

            <View style={{background: theme.bodyColor, alignItems: 'center', padding: 10, marginBottom: 1}} onClick={() => window.open('https://admin.turfeaposta.com/#/polities')}>
                <Label style={{fontSize: '4vw', fontWeight: 'bold'}}>Políticas de privacidade</Label>
            </View>

            <View style={{background: theme.bodyColor, justifyContent: "center", alignItems: "center", flexDirection: 'row', paddingTop: 10}}>
                <Label style={{marginRight: 10}}>Modo escuro</Label>
                <Switch
                    checked={theme === dark}
                    onChange={handleChangeTheme}
                />
            </View>

            <View style={{background: theme.bodyColor, alignItems: 'center', padding: 10, marginBottom: 1}} onClick={handleExit}>
                <Label color={red} style={{fontSize: '4vw', fontWeight: 'bold'}}>Sair da conta</Label>
            </View>
        </View>
        <View style={{flex: 1, justifyContent: "flex-end", alignItems: "center"}}>
            <Label>Versão {version}</Label>
            <View style={{flexDirection: 'row', justifyContent: "center", alignItems: "flex-end", paddingBottom: 20}} onClick={handleOpenAVA}>
                <Label>Powered by</Label>
                <img
                    src={theme.AVAlogo}
                    alt=""
                    style={{
                        width: "50vw",
                        height: '10vw',
                        marginLeft: 10
                    }}
                />
            </View>
        </View>
    </PageContainer>
};

const mapStateToProps = ({ user }) => ({ theme: user.theme, user })

export default connect(mapStateToProps, { logoff, changeTheme })(More);

import firebase from "firebase";
import axios from 'axios';
import { LOAD_USER, LOGOFF_USER, RESCUE_MONEY, EDIT_FORM, RESET_FORM, ADD_MONEY, UPDATE_MONEY, CHANGE_THEME } from "./types";
import dark from "../theme/dark";
import light from "../theme/light";

export const loadUser = uid => async dispatch => {
    try {        
        const userData = (await firebase.database().ref(`users/${uid}`).once("value")).val();

        console.log(userData);

        userData.key = uid;

        if (userData.status === 0) {
            return "Usuário desabilitado"
        }

        if (userData.theme === 'dark') {
            userData.theme = dark;
        } else {
            userData.theme = light;
        }

        dispatch({
            type: LOAD_USER,
            payload: userData
        });

        return true;
    } catch (e) {
        console.log(e);
        return "Erro ao carregar dados do usuário";
    }
}

export const logoff = () => ({
    type: LOGOFF_USER
})

export const joinBet = (betData, horseData, newAmount) => async dispatch => {
    try {
        const {uid} = firebase.auth().currentUser;
        const {data} = await axios.post(
            'https://us-central1-jockeybet-d7337.cloudfunctions.net/joinBet',
            {
                uid,
                betData,
                horseData,
            },
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
            },
        );

        dispatch({
            type: UPDATE_MONEY,
            newAmount
        })
    
        return data;
    } catch (e) {
        console.error(e);
    }
};

export const updateMoney = money => ({
    type: UPDATE_MONEY,
    newAmount: money
})

export const rescueMoney = (
    value,
    name,
    cpf,
    bank,
    AG,
    account,
    digit
) => async dispatch => {
    try {
        const {uid} = firebase.auth().currentUser;
        const userRef = firebase.database().ref(`users/${uid}`);
        const paymentsRef = userRef.child('/payments');
        const newPayments = paymentsRef.push();
        const moneyData = await userRef.child('/money').once('value');
        const data = {
            createdAt: new Date().getTime(),
            amount: value,
            status: 0,
            type: 0,
            name,
            cpf,
            bank,
            AG,
            account,
            digit
        };

        const newAmount = moneyData.val() - value;

        await newPayments.set(data);
        await userRef.update({money: newAmount});

        const paymentsList = await paymentsRef.once('value');

        dispatch({
            type: RESCUE_MONEY,
            newAmount,
            paymentsList: paymentsList.val(),
        });
    } catch (e) {
      console.error(e);
    }
};


export const addMoney = (
    name,
    email,
    cpf,
    cvc,
    expiry,
    number,
    value
) => async dispatch => {
    try {
        const {uid} = firebase.auth().currentUser;
        console.log(uid)
        const {data} = await axios.post(
            // 'https://us-central1-jockeybet-d7337.cloudfunctions.net/payment2',
            'https://us-central1-jockeybet-d7337.cloudfunctions.net/paymentByCard',
            {
                userKey: uid,
                customer: {
                    name,
                    email,
                    identity: cpf,
                    // phone
                },
                card: {
                    number: number.replace(/\s/g, ''),
                    holder: name,
                    expirationDate: expiry.replace(/(.{2})/, '$1\/20'),
                    securityCode: cvc,
                    brand: detect(number, true),
                    // token: CardToken,
                },
                paymentAmount: value,
                // bank,
                // description,
            },
            {
                headers: {
                    // Accept: 'application/json',
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*',
                    // 'Access-Control-Allow-Credentials': true,
                },
            },
        );
      
        if (data.success) {
            // dispatch({
            //     type: ADD_MONEY,
            //     newAmount: data.amount,
            //     creditCard: data.creditCard,
            //     payments: data.payments,
            // });
    
            return true;
        } else {
            return data.errorCode;
        }
    } catch (e) {
        console.error(e);
    }
};

export const editForm = payload => ({
    type: EDIT_FORM,
    payload
});

export const resetForm = () => ({
    type: RESET_FORM
});

export const AMEX = "american-express";
export const DINERS = "diners-club";
export const DISCOVER = "discover";
export const JCB = "jcb";
export const MAESTRO = "maestro";
export const MASTERCARD = "master-card";
export const VISA = "visa";
export const UNKNOWN = "UNKNOWN";

export const AMEX_CIELO = "Amex";
export const DINERS_CIELO = "Diners";
export const DISCOVER_CIELO = "Discover";
export const JCB_CIELO = "JCB";
export const MASTERCARD_CIELO = "Master";
export const VISA_CIELO = "Visa";

export const detect = (s, isCielo) => {
    if (s.length > 0) {
        switch (s.substring(0, 1)) {
            case "2":
                if (s.length > 5) {
                    let i = s.substring(0, 7).valueOf();

                    if (i >= 222100 && i <= 272099) return isCielo ? MASTERCARD_CIELO : MASTERCARD ;
                    else return UNKNOWN;

                } else return UNKNOWN;
            case "3":
                if (s.length > 1) {
                    switch (s.substring(0, 2)) {
                        case "36":
                        case "38":
                        case "39":
                            return isCielo ? DINERS_CIELO : DINERS;
                        case "34":
                        case "37":
                            return isCielo ? AMEX_CIELO : AMEX;
                        default:
                            if (s.length > 2) {
                                switch (s.substring(0, 3)) {
                                    case "300":
                                    case "301":
                                    case "302":
                                    case "303":
                                    case "304":
                                    case "305":
                                        return isCielo ? DINERS_CIELO : DINERS;
                                    default:
                                        if (s.length > 3) {
                                            switch (s.substring(0, 4)) {
                                                case "3095":
                                                    return isCielo ? DINERS_CIELO : DINERS;
                                                default:
                                                    if (s.substring(0, 4).valueOf() >= 3528 && s.substring(0, 4).valueOf() <= 3589) 
                                                        return isCielo ? JCB_CIELO : JCB;
                                                    else 
                                                        return UNKNOWN;
                                            }
                                        } else return UNKNOWN;
                                }
                            } return UNKNOWN;
                    }
                } else return UNKNOWN;
            case "4":
                return isCielo ? JCB_CIELO : JCB;
            case "5":
                if (s.length > 1) {
                    switch (s.substring(0, 2)) {
                        case "51":
                        case "52":
                        case "53":
                        case "54":
                        case "55":
                            return isCielo ? MASTERCARD_CIELO : MASTERCARD;
                        case "50":
                        case "56":
                        case "57":
                        case "58":
                            return MAESTRO;
                        default:
                            return UNKNOWN;
                    }
                } else return UNKNOWN;
            case "6":
                if (s.length > 1) {
                    switch (s.substring(0, 2)) {
                        case "67":
                            return MAESTRO;
                        case "64":
                        case "65":
                            return isCielo ? DISCOVER_CIELO : DISCOVER;
                        default:
                            if (s.length > 3) {
                                if (s.substring(0, 4) === "6011") return isCielo ? DISCOVER_CIELO : DISCOVER;
                                else return UNKNOWN;
                            } else return UNKNOWN;
                    }
                } else return UNKNOWN;
            default:
                return UNKNOWN;
        }
    } else return UNKNOWN;
}


export const changeTheme = theme => ({
    type: CHANGE_THEME,
    theme
})
import React, { useEffect } from "react";
import { Switch, Route, Redirect, useHistory, useLocation } from "react-router-dom";
import firebase from 'firebase';
import { connect } from "react-redux";

import BottomTabBar from "../components/BottomTabBar";

import Login from "../pages/Login";
import Register from "../pages/Register";
import ResetPassword from "../pages/ResetPassword";

import Home from "../pages/Home";
import BetData from "../pages/BetData";
import ConfirmBet from "../pages/ConfirmBet";
import ConfirmBetDone from "../pages/ConfirmBet/done";

import MyBets from "../pages/MyBets";

import Wallet from "../pages/Wallet";
import Rescue from "../pages/Rescue";
import RescueData from "../pages/Rescue/data";
import RescueDone from "../pages/Rescue/done";
import AddMoney from "../pages/AddMoney";
import AddMoneyData from "../pages/AddMoney/data";
import AddMoneyDone from "../pages/AddMoney/done";

import More from "../pages/More";
import Loading from "../pages/Loading";

import { loadUser } from "../actions";

const MainRoute = ({ loadUser }) => {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        firebase.auth().onAuthStateChanged(async (user) => {
            const routePath = location.pathname;

            if (routePath === "/register") {
                return false;
            }

            if (!user) {
                if (!["/login", "/register", "/resetpassword"].includes(routePath)) {
                    history.push("/login");
                }
            } else {
                const snap = await firebase.database().ref(`users/${user.uid}/status`).once('value');
                const userStatus = snap.val();

                console.log('User status =>', userStatus)

                if (userStatus === 0 || !userStatus) {
                    if (!["/login", "/register", "/resetpassword"].includes(routePath)) {
                        history.push("/login");
                    }
                } else if (["/", "/login", "/register", "/resetpassword"].includes(routePath)) {
                    history.push("/home");
                }
            }
        });
    }, [history, location.pathname]);

    return <Switch>
        <Route exact path="/" component={Loading} />

        
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/resetpassword" component={ResetPassword} />
        
        <Route path="/bet/:betKey/" exact component={BetData} />
        <Route path="/bet/:betKey/confirm" component={ConfirmBet} />
        <Route path="/bet/:betKey/done" component={ConfirmBetDone} />
        
        <Route path="/rescue/" exact component={Rescue} />
        <Route path="/rescue/data" component={RescueData} />
        <Route path="/rescue/done" component={RescueDone} />
        
        <Route path="/addmoney/" exact component={AddMoney} />
        <Route path="/addmoney/data" component={AddMoneyData} />
        <Route path="/addmoney/done" component={AddMoneyDone} />
        
        {/* Rotas principais */}
        <BottomTabBar>
            <Route
                path="/home"
                mainPath="/home"
                exact
                component={Home}
                icon={require("../images/ic_home.png")}
                iconSelected={require("../images/ic_ home_selected.png")}
                name="INICIO"
            />
            <Route
                path="/mybets" 
                mainPath="/mybets"
                component={MyBets}
                icon={require("../images/ic_bet.png")}
                iconSelected={require("../images/ic_ bet_selected.png")}
                name="APOSTAS"
            />
            <Route
                path="/wallet" 
                mainPath="/wallet"
                component={Wallet}
                icon={require("../images/ic_payment.png")}
                iconSelected={require("../images/ic_ payment_selected.png")}
                name="CARTEIRA"
            />
            <Route
                path="/more" 
                component={More}
                mainPath="/more"
                icon={require("../images/ic_account.png")}
                iconSelected={require("../images/ic_ account_selected.png")}
                name="MAIS"
            />
        </BottomTabBar>
        
        <Redirect to="/login" />
    </Switch>
}

const mapStateToProps = ({user}) => ({user});

export default connect(mapStateToProps, { loadUser })(MainRoute);
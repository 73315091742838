import React from 'react';
import { headColor, bodyColor, whitePurble, green, grey, yellow } from '../../../theme';
import View from '../../View';
import Label from '../../Label';
import { format } from 'currency-formatter';

import { isSafari } from "../../../utils";
import styled from 'styled-components';

const RowContainer = styled.div`
    display: flex;
    align-self: stretch;
    align-items: center;
    padding: ${props => props.type === 0 ? '10px' : '20px'} 10px;
    height: ${props => props.type === 0 ? 20 : 80};
    border-bottom: 1px solid ${props => props.theme.theme.homeRowBorderColor};
    flex-direction: row;
    justify-content: center;
    background: ${props => props.theme.theme.bodyColor};
`;

const CheckBox = styled.div`
    display: flex;
    align-self: stretch;
    flex-direction: column;

    border-radius: 50px;
    border: 1px solid ${grey};
    background: ${props => props.value ? yellow : props.theme.theme.footColor };
    width: 20px;
    height: 20px;
    position: absolute;
`;

const Row = ({fields, type, background, onSelect}) => (
    <RowContainer onClick={onSelect} type={type}>
        {
            fields.map(field => {
                switch(field.type) {
                    case "checkbox":
                        return (
                            <View style={{width: (field.width || 0)+"vw", marginRight: 5, alignItems: 'center', justifyContent: 'center', position: 'relative'}}>
                                <CheckBox value={field.value} />
                            </View>
                        );
                    case "text":
                        return (
                            <View style={{/*border: '1px solid #fff',*/ width: (field.width || 0)+"vw", justifyContent: 'center', textAlign: field.align || 'left'}}>
                                <Label style={{height: 'fit-content', fontSize: field.fontSize || 14}}>{field.title}</Label>
                            </View>
                        );
                    case "double":
                        return (
                            <View style={{height: 'fit-content', width: (field.width || 0)+"vw", alignSelf: 'center', justifyContent: 'center'}}>
                                <Label style={{height: 14, fontSize: field.fontSize || 12}}>1 - {field.title.split('-')[0]}</Label>
                                <Label style={{height: 14, fontSize: field.fontSize || 12}}>2 - {field.title.split('-')[1]}</Label>
                            </View>
                        );
                    case "currency":
                        return (
                            <View style={{/*border: '1px solid #f51',*/ width: (field.width || 0)+"vw", justifyContent: 'center'}}>
                                <Label style={{height: 'fit-content', fontSize: 14, textAlign: 'right', color: field.color}}>{format(field.title/100, {code: "BRL"})}</Label>
                            </View>
                        );
                    case "percent":
                        return (
                            <View style={{/*border: '1px solid #4c7',*/ width: (field.width || 0)+"vw", justifyContent: 'center'}}>
                                <Label style={{height: 'fit-content', fontSize: 14, textAlign: 'right'}}>{field.title.toFixed(1).replace('.', ',')}</Label>
                            </View>
                        );
                    default:
                        return <View style={{width: (field.width || 0)+"vw"}} />;
                }
            })
        }        
    </RowContainer>
)

const Header = () => (
    <Row
        fields={[
            {title: "", type: "null", width: 7},
            {title: "Cavalos", type: "text", width: 50},
            {title: "Rateio", type: "text", width: 20, align: 'right'},
            {title: "Aposta máxima", type: "text", width: 25, align: 'right', color: '#FFF'}
        ]}
        type={0}
    />
);

const BetGrid = ({horses, selected, onSelect, type}) => {
    return <View style={{flexDirection: 'column', height: '100%'}}>
        <Header />
        <View style={{flexDirection: 'column', overflow: 'auto', height: '100%'}}>
            {
                horses.map(horse => (
                    <Row
                        onSelect={() => onSelect(horse.key)}
                        background={headColor}
                        type={type}
                        fields={[
                            {
                                value: horse.key === selected, 
                                type: "checkbox", 
                                width: 7
                            },
                            {title: horse.name, type: type === 1 ? "double" : "text", width: 50, fontSize: 12},
                            {title: horse.ratio, type: "percent", width: 20},
                            {title: horse.limit - horse.amount, type: "currency", width: 25, color: green}
                        ]} 
                    />
                ))
            }
        </View>
    </View>
};

export default BetGrid;

export default {
    bodyColor: "#000",
    headColor: "#121212",
    footColor: '#121212',
    inputColor: '#262626',
    homeTitle: '#000',
    homeValue: '#509C2C',
    homeRowBorderColor: '#3b3b3b',
    color: "#f4f4f4",
    gradient: 'linear-gradient(180deg, #171616, #262626)',
    walletAddText: '#ffca28',
    walletRemoveText: '#ef5350',
    walletBalanceColor: '#509C2C',



    iconAdd: require('../images/0 ic_add_yellow.png'),
    iconRemove: require('../images/ic_retirar_red.png'),
    iconBack: require("../images/ic_back.png"),
    iconExit: require("../images/ic_close.png"),
    emptyState: require("../images/img_noresults.png"),
    logo: require("../images/logo_splash.png"),
    AVAlogo: require("../images/avamobile.png"),
}
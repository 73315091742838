import React, { useEffect } from 'react'
import { connect } from "react-redux";
import { ThemeProvider } from 'styled-components';

import dark from './dark';
import ThemeContext from "./context";

import { changeTheme } from "../actions";
import light from './light';

const App = ({theme, changeTheme, children}) => {
    useEffect(() => {
      if (theme !== dark && theme !== light) {
        const data = localStorage.getItem('theme')

        if (!data || data === 'light') {
          localStorage.setItem('theme', 'light')
          changeTheme(light)
        } else {
          changeTheme(dark)
          localStorage.setItem('theme', 'dark')
        }
      }
    }, [theme])

    return (
        <ThemeContext.Provider value={{theme: theme || light}}>
          <ThemeContext.Consumer>
            {theme => (
              <ThemeProvider theme={theme}>
                {children}
              </ThemeProvider>
            )}
          </ThemeContext.Consumer>
        </ThemeContext.Provider>
    )
}

const mapStateToProps = ({user}) => ({theme: user.theme})

export default connect(mapStateToProps, { changeTheme })(App);
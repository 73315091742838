import React, { useState } from 'react';
import { connect } from "react-redux";
import { useLocation, useHistory } from 'react-router-dom';
import { joinBet } from "../../actions";
import Loading from "react-loading";

import PageContainer from '../../components/PageContainer';
import View from '../../components/View';
import Label from '../../components/Label';
import Header from '../../components/Header/Home';
import { grey, green } from '../../theme';
import { format } from 'currency-formatter';
import Button from '../../components/Button';

const ConfirmBet = ({user, theme, joinBet}) => {
    const location = useLocation();
    const history = useHistory();
    const {horse, bet, betKey, value} = location.state;
    const [loading, setLoading] = useState(false);

    const handleMsgError = code => {
        const ERROR_BET_DATA = -1;
        const ERROR_HORSE_DATA = -2;
        const LOWER_THAN_MIN = -3;
        const HIGHER_THAN_LIMIT = -4;
        const ERROR_BET_PERIOD = -5;
    
        switch (code) {
            case ERROR_BET_DATA:
            case ERROR_HORSE_DATA:
            default:
                return 'Aconteceu algum erro interno, tente novamente mais tarde';
            case LOWER_THAN_MIN:
                return 'Valor da aposta é menor que o mínimo';
            case HIGHER_THAN_LIMIT:
                return `Valor da aposta é maior que valor permitido, verifique novamente o limite máximo do cavalo ${horse.name}`;
            case ERROR_BET_PERIOD:
                return "Não é possível realizar apostas fora do período de apostas";
        }
    };
    
    const approvBet = async () => {
        if (loading) {
            return false;
        }
        setLoading(true)
        if (value === 0) {
            alert('Ops!', 'Ocorreu um erro, tente novamente mais tarde!');
            setLoading(false)
            return false;
        }

        try {
            const {success, ...data} = await joinBet(
                {
                    betKey, 
                    betName: bet.name, 
                    value: parseInt(value * 100, 10)
                },
                {
                    horseKey: horse.key, 
                    version: horse.index, 
                    horseName: horse.name, 
                    ratio: horse.ratio, 
                    limit: horse.limit, 
                    amount: horse.amount
                },
                user.money - parseInt(value * 100, 10),
                user.payments
            );

            if (success) {
                history.push("done")
            } else {
                alert(handleMsgError(data.errorCode));
                setLoading(false)
            } 
        } catch (e) {
            console.warn(e);
            setLoading(false)
            alert(handleMsgError());
        }
    };

    return <PageContainer style={{height: '100%'}}>
        <Header user={user} />
        <View style={{padding: 20}}>
            <Label style={{marginBottom: 20, fontSize: 20, fontWeight: 'bold'}}>Confirmação da aposta</Label>
            <View style={{flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10}}>
                <View style={{alignItems: 'flex-start'}}>
                    <Label style={{marginBottom: 10, fontSize: 14, color: grey}}>Cavalo selecionado</Label>
                    <Label style={{marginBottom: 10, fontSize: 16}}>{horse.name}</Label>
                </View>
                <View style={{alignItems: 'center'}}>
                    <Label style={{marginBottom: 10, fontSize: 14, color: grey}}>Valor da aposta</Label>
                    <Label style={{marginBottom: 10, fontSize: 16, fontWeight: 'bold'}}>{format(value, { code: "BRL" })}</Label>
                </View>
            </View>

            <Button color="green" style={{width: "100%", height: 60}} onClick={approvBet}>
                {
                    loading ? <Loading width="30px" height="30px" type="spin" />
                    : <Label color={theme.bodyColor}>APOSTAR</Label>

                }
            </Button>
        </View>
    </PageContainer>
};

const mapStateToProps = ({user}) => ({user, theme: user.theme});

export default connect(mapStateToProps, { joinBet })(ConfirmBet);

import React, { useState } from 'react';
import firebase from 'firebase';
import Loading from "react-loading";

import PageContainer from "../../components/PageContainer";
import Label from '../../components/Label';
import Button from "../../components/Button";
import Input from "../../components/Input";
import View from '../../components/View';

import { whitePurble } from "../../theme";

const ResetPassword = ({history}) => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSendEmail = async () => {
        try {
            setLoading(true);
            await firebase.auth().sendPasswordResetEmail(email);
            history.push("/login");
            setLoading(false);
        } catch (e) {
            setLoading(false);
            alert("Erro ao enviar o e-mail\nVerifique se esta correto o seu e-mail");
        }
    }

    return <PageContainer style={{height: '100%'}}>
        <View style={{alignItems: 'center', paddingLeft: 20}}>
            <img src={require("../../images/logo_splash.png")} style={{width: '50vw', height: '50vw'}} alt="" />
        </View>
        <View style={{alignItems: 'flex-start', paddingLeft: 20, marginBottom: 10}}>
            <Label fontSize={20}>Esqueceu a senha?</Label>
        </View>
        <View style={{alignItems: 'center', marginBottom: 20}}>
            <Label style={{alignSelf: 'flex-start', paddingLeft: 20}}>E-mail</Label>
            <Input value={email} onChange={e => setEmail(e.target.value)} type="email"/>
        </View>
        <View style={{alignItems: 'center', marginBottom: 20}}>
            <Button color="whiteBlue" onClick={handleSendEmail}>
                {
                    loading
                        ? <View style={{alignItems: 'center', justifyContent: 'center'}}>
                            <Loading type="spin" width="25px" height="25px" />
                        </View>
                        : <Label>ENVIAR</Label>
                }
            </Button>
        </View>
        <View style={{alignItems: 'flex-start', paddingLeft: 20, marginBottom: 20}}>
            <Label onClick={() => history.push("/login")}>Voltar</Label>
        </View>
    </PageContainer>
};

export default ResetPassword;
